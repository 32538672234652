import React from "react";
import { ReactComponent as LogoAlternate } from "../assets/images/logo-dalou-alternate.svg";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="brand-name">
          <LogoAlternate className="logo-alternate" />
        </div>
        <div className="content-container">
          <div className="footer-content">
            <div className="leftcol">
              <a
                href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
                target="_blank"
                rel="noreferrer"
              >
                <div className="footer-btn mobile">Nos cartes et formules</div>
              </a>
              <div className="footer-separator mobile"></div>
              <div className="leftcol-title">Brasserie Parisienne à Nation</div>

              <div className="leftcol-content">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM10.45 9.5V4.75H8.55V11.4H14.25V9.5H10.45Z"
                      fill="#8B252A"
                    />
                  </svg>
                </span>
                Ouvert du lundi au dimanche 24/24h, <br /> service continu
              </div>
            </div>
          </div>
          <div className="footer-separator desktop"></div>
          <div className="leftcol leftcol2">
            <div className="leftcol-content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="23"
                viewBox="0 0 13 23"
                fill="none"
              >
                <path
                  d="M6.50002 0C2.91018 0 0 2.95534 0 6.6009C0.00260718 7.72013 0.285462 8.82044 0.548929 9.30929L6.50002 23L12.1876 9.79819H12.1846C12.7188 8.81981 12.9994 7.71962 13 6.6009C13 2.95534 10.0899 0 6.50002 0ZM6.50002 3.3004C6.92682 3.3004 7.34944 3.38577 7.74375 3.55164C8.13806 3.71751 8.49634 3.96062 8.79813 4.2671C9.09992 4.57358 9.33931 4.93743 9.50263 5.33786C9.66595 5.7383 9.75 6.16748 9.74999 6.6009C9.74999 7.47622 9.40758 8.3157 8.7981 8.93464C8.18861 9.55359 7.36197 9.90131 6.50002 9.90131C5.63808 9.90131 4.81144 9.55359 4.20195 8.93464C3.59246 8.3157 3.25006 7.47622 3.25006 6.6009C3.25006 5.72558 3.59246 4.88611 4.20195 4.26717C4.81144 3.64822 5.63808 3.3005 6.50002 3.3005V3.3004Z"
                  fill="#8B252A"
                />
              </svg>
              30 Place de la Nation, 75012 Paris, France
            </div>
            <div className="leftcol-content">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M11.8592 10.2645L11.4295 10.6909C11.4295 10.6909 10.4066 11.7036 7.61581 8.93841C4.825 6.17321 5.84783 5.16049 5.84783 5.16049L6.11793 4.89131C6.78565 4.2306 6.84893 3.16895 6.26621 2.39341L5.07622 0.809399C4.35467 -0.150609 2.96162 -0.277669 2.13524 0.541162L0.652471 2.00941C0.243529 2.416 -0.030358 2.94118 0.00269731 3.52471C0.0876968 5.01837 0.765803 8.23064 4.54733 11.9784C8.55836 15.9521 12.3219 16.1102 13.8604 15.9672C14.3478 15.922 14.7709 15.6754 15.1118 15.3366L16.4529 14.0076C17.3596 13.1107 17.1046 11.5718 15.9448 10.9441L14.1409 9.96618C13.3797 9.55488 12.4542 9.67535 11.8592 10.2645Z"
                  fill="#8B252A"
                />
              </svg>
              <b>01 43 43 41 38</b>
            </div>
            <div
              className="footer-separator mobile"
              style={{ marginBottom: "5vw", marginTop: "5vw" }}
            ></div>
          </div>
        </div>
        <div className="footer-links">
          <a
            href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
            target="_blank"
            rel="noreferrer"
          >
            <div className="footer-btn desktop">Nos cartes et formules</div>
          </a>
        </div>

        <div className="footer-links">
        <a href="https://www.instagram.com/le.dalou/" target="_blank" rel="noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
          >
            <path
              d="M10.73 0H26.27C32.19 0 37 4.81 37 10.73V26.27C37 29.1158 35.8695 31.845 33.8573 33.8573C31.845 35.8695 29.1158 37 26.27 37H10.73C4.81 37 0 32.19 0 26.27V10.73C0 7.88423 1.13048 5.15501 3.14274 3.14274C5.15501 1.13048 7.88423 0 10.73 0ZM10.36 3.7C8.59366 3.7 6.89966 4.40168 5.65067 5.65067C4.40168 6.89966 3.7 8.59366 3.7 10.36V26.64C3.7 30.3215 6.6785 33.3 10.36 33.3H26.64C28.4063 33.3 30.1003 32.5983 31.3493 31.3493C32.5983 30.1003 33.3 28.4063 33.3 26.64V10.36C33.3 6.6785 30.3215 3.7 26.64 3.7H10.36ZM28.2125 6.475C28.8258 6.475 29.414 6.71864 29.8477 7.15232C30.2814 7.58599 30.525 8.17419 30.525 8.7875C30.525 9.40081 30.2814 9.98901 29.8477 10.4227C29.414 10.8564 28.8258 11.1 28.2125 11.1C27.5992 11.1 27.011 10.8564 26.5773 10.4227C26.1436 9.98901 25.9 9.40081 25.9 8.7875C25.9 8.17419 26.1436 7.58599 26.5773 7.15232C27.011 6.71864 27.5992 6.475 28.2125 6.475ZM18.5 9.25C20.9533 9.25 23.306 10.2246 25.0407 11.9593C26.7754 13.694 27.75 16.0467 27.75 18.5C27.75 20.9533 26.7754 23.306 25.0407 25.0407C23.306 26.7754 20.9533 27.75 18.5 27.75C16.0467 27.75 13.694 26.7754 11.9593 25.0407C10.2246 23.306 9.25 20.9533 9.25 18.5C9.25 16.0467 10.2246 13.694 11.9593 11.9593C13.694 10.2246 16.0467 9.25 18.5 9.25ZM18.5 12.95C17.028 12.95 15.6164 13.5347 14.5756 14.5756C13.5347 15.6164 12.95 17.028 12.95 18.5C12.95 19.972 13.5347 21.3836 14.5756 22.4244C15.6164 23.4653 17.028 24.05 18.5 24.05C19.972 24.05 21.3836 23.4653 22.4244 22.4244C23.4653 21.3836 24.05 19.972 24.05 18.5C24.05 17.028 23.4653 15.6164 22.4244 14.5756C21.3836 13.5347 19.972 12.95 18.5 12.95Z"
              fill="#8B252A"
            />
          </svg>
          </a>
        </div>
      </div>

      <div className="underfooter">
        <p>© 2024 Brief Tous droits réservés - Mentions légales</p>
      </div>
    </>
  );
};

export default Footer;
