import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/images/logo-dalou.svg";
import { ReactComponent as LogoAlternate } from "../assets/images/logo-dalou-alternate.svg";
import { ReactComponent as IconInsta } from "../assets/images/insta-icon.svg";

const Navbar = ({ section }) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   console.log("Toggling Menu"); // Debug: Check if this logs when clicking
  //   setIsMenuOpen(!isMenuOpen);
  // };

  return (
    <nav className={`navbar ${section}`}>
      <div className="logo-container">
        {section === "section1" || section === "section3" ? (
          <Logo className="logo" />
        ) : (
          <LogoAlternate className="logo-alternate" />
        )}
      </div>
      <div className="nav-items">
        <a href="#section2" className="nav-link">
          Nos cartes, formules & infos pratiques
        </a>
        <a href="#section3" className="nav-link">
          Contact
        </a>
        <a href="https://www.instagram.com/le.dalou/" target="_blank" rel="noreferrer">
        <IconInsta className="icon-insta" />
        </a>
        {/* <div className="hamburger-menu">
          <div className="menu-icon" onClick={toggleMenu}>
            ☰
          </div>
          {isMenuOpen && (
            <div className="menu-content" id="menuContent">
              <a href="#">Link 1</a>
              ceci est un commentaire 
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          )}
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;