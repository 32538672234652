import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ScrollIcon } from "../assets/images/scroll-icon.svg";
import Moules from "../assets/images/moules.png";
import Choucrout from "../assets/images/dalou-2.jpeg";
import Navbar from "./nav";
import Footer from "./footer";
import Insta1 from "../assets/images/Image_1_Le_Dalou_Instagram.png";
import Insta2 from "../assets/images/Image_2_Le_Dalou_Instagram.png";
import Insta3 from "../assets/images/Image_3_Le_Dalou_Instagram.png";
import Insta4 from "../assets/images/Image_4_Le_Dalou_Instagram.png";
import Insta5 from "../assets/images/Image_5_Le_Dalou_Instagram.png";
import Slider1 from "../assets/images/img_resp_1.png";
import Slider2 from "../assets/images/img_resp_2.png";
import Slider3 from "../assets/images/img_resp_3.png";
import Slider4 from "../assets/images/img_resp_4.png";
import ScndSlider1 from "../assets/images/1_MOULES_LE_DALOU.png";
import ScndSlider2 from "../assets/images/2_CHOUCROUTE_LE_DALOU.png";
import ScndSlider3 from "../assets/images/3_PLATEAUX_FRUITS_MER.png";
import Address from "../assets/images/adress.png";

const Body = () => {
  const firstSliderRef = useRef(null);
  const secondSliderRef = useRef(null);
  const thirdSliderRef = useRef(null);

  const [currentFirstSlide, setCurrentFirstSlide] = useState(0);
  const [currentSecondSlide, setCurrentSecondSlide] = useState(0);
  const [currentThirdSlide, setCurrentThirdSlide] = useState(0);

  const [activeSection, setActiveSection] = useState("section1");

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);

  const slides = [Slider1, Slider2, Slider3, Slider4];

  const scndSlides = [ScndSlider1, ScndSlider2, ScndSlider3];

  const thirdSlides = [Insta1, Insta2, Insta3, Insta4, Insta5]; 

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - firstSliderRef.current.offsetLeft);
    setScrollStart(firstSliderRef.current.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - firstSliderRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    firstSliderRef.current.scrollLeft = scrollStart - walk;
  };

  const navigateToSlide = (index, setSlide) => {
    setSlide(index);
  };

  const getSlideStyles = (index, currentSlide) => {
    return {
      transform: `translateX(-${currentSlide * 100}%)`, // Correctly use the currentSlide to calculate position
      transition: "transform 0.3s ease-out",
      width: "100%",
      flexShrink: 0,
    };
  };
  

  const nextSlide = () => {
    setCurrentFirstSlide(prevSlide => (prevSlide + 1) % slides.length);
  };
  
  const prevSlide = () => {
    setCurrentFirstSlide(prevSlide => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const isMobile = () => window.innerWidth < 768;

  useEffect(() => {
    const handleScroll = () => {
      const section1 = document.getElementById("section1");
      const section2 = document.getElementById("section2");
      const section3 = document.getElementById("section3");

      if (section1 && section2 && section3) {
        const section1Top =
          section1.getBoundingClientRect().top + window.scrollY;
        const section2Top =
          section2.getBoundingClientRect().top + window.scrollY;
        const section3Top =
          section3.getBoundingClientRect().top + window.scrollY;
        const currentScroll = window.scrollY;

        if (currentScroll >= section1Top && currentScroll < section2Top) {
          setActiveSection("section1");
        } else if (
          currentScroll >= section2Top &&
          currentScroll < section3Top
        ) {
          setActiveSection("section2");
        } else if (currentScroll >= section3Top) {
          setActiveSection("section3");
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isMobile()) {
      const sectionElement = document.getElementById(activeSection);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [activeSection]);

  return (
    <>
      <Navbar section={activeSection} />
      {/* DESKTOP */}
      <section id="section1" className="desktop">
        <div className="hero-bubble bubble1">
          <img src={Address} className="address" />
          {/* <span className="bubble-span">30 Place de la Nation</span>
          <div className="bubble-separator"></div>
          <span className="bubble-span">Paris 12e</span> */}
        </div>
        <div className="hero-content">
          <h1 className="hero-title">
            “Probablement
            <br /> l‘une des plus <span>Parisiennes</span>
            <br /> des brasseries !”
          </h1>
          <div className="hero-separator"></div>
          <h3 className="hero-subtitle">Petit futé - 2024</h3>
          <a
            href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
            target="_blank"
            rel="noreferrer"
          >
            <h2 className="hero-button">Nos cartes et formules</h2>
          </a>

          <ScrollIcon className="scroll-icon" />
        </div>
      </section>
      {/* --------------------------------------------------------------- */}
      {/* MOBILE */}
      <section id="section1" className="mobile">
        <div className="hero-bubble bubble1">
          <img src={Address} className="address" />
          {/* <span className="bubble-span">
            30 Place de <br></br>la Nation
          </span>
          <div className="bubble-separator"></div>
          <span className="bubble-span">Paris 12e</span> */}
        </div>
        <div className="hero-content">
          <h1 className="hero-title">
            “Probablement l‘une des plus <br />
            <span>Parisiennes</span>
            <br /> <span className="herospan1">des brasseries !”</span>
          </h1>
          <div className="hero-separator"></div>
          <h3 className="hero-subtitle">Petit futé - 2024</h3>
          <div className="slider-container" ref={firstSliderRef}>
            <div
              className="slider"
              onMouseDown={onMouseDown}
              onMouseLeave={onMouseLeave}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
            >
              {slides.map((slide, index) => (
                <img key={index} src={slide} alt={`Slide ${index + 1}`} />
              ))}
            </div>
            <button className="prev" onClick={prevSlide}>
              &lt;
            </button>
            <button className="next" onClick={nextSlide}>
              &gt;
            </button>
          </div>
          <a
            href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
            target="_blank"
            rel="noreferrer"
          >
            <h2 className="hero-button">Nos cartes et formules</h2>
          </a>
          <ScrollIcon className="scroll-icon" />
        </div>
      </section>
      {/* ------------------------------------------------------------------ */}
      <section id="section2">
        <div className="absolute-yellow"></div>
        <div className="subsection">
          <div className="subsubsection">
            <h1 className="hero2-title">
              <span className="hero2-span">Des amoureux</span> <br />
              <span className="hero2-span2">de la cuisine française</span>
            </h1>
            <p className="description mobile">
              <span>Le dalou</span> vous propose une carte à l’image de la
              cuisine française et qui change régulièrement, dans un décor art
              déco typiquement parisien. Les spécialités plébiscitées par nos
              clients ne varient guère mais on vient pour la tradition –
              choucroute en tête – autant que pour les plateaux de fruits de
              mer.
            </p>
            <div className="section2-buttons mobile">
              <a
                href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button1">Nos cartes et formules</div>
              </a>
            </div>
            <div className="scnd-slider-box mobile">
              <div className="mobile-yellow-bg"></div>
              <div
                className="scnd-slider-container"
                style={{ display: "flex", overflow: "hidden" }}
                ref={secondSliderRef} // make sure this ref is used for the correct slider
              >
                {scndSlides.map((slide, index) => (
                  <img
                    key={index}
                    src={slide}
                    alt={`Slide ${index + 1}`}
                    style={getSlideStyles(index, currentSecondSlide)} // Corrected to use currentSecondSlide
                  />
                ))}
              </div>
              <div className="slider-dots">
                {scndSlides.map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${
                      currentSecondSlide === index ? "active" : ""
                    }`}
                    onClick={() => setCurrentSecondSlide(index)}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </div>
            </div>
            <div className="info-section mobile">
              <div className="info-title">
                Infos pratiques<span className="title-sideline"></span>
              </div>
              <div className="info-content">
                <p className="info-item">
                  <span className="info-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="23"
                      viewBox="0 0 13 23"
                      fill="none"
                    >
                      <path
                        d="M6.50002 0C2.91018 0 0 2.95534 0 6.6009C0.00260718 7.72013 0.285462 8.82044 0.548929 9.30929L6.50002 23L12.1876 9.79819H12.1846C12.7188 8.81981 12.9994 7.71962 13 6.6009C13 2.95534 10.0899 0 6.50002 0ZM6.50002 3.3004C6.92682 3.3004 7.34944 3.38577 7.74375 3.55164C8.13806 3.71751 8.49634 3.96062 8.79813 4.2671C9.09992 4.57358 9.33931 4.93743 9.50263 5.33786C9.66595 5.7383 9.75 6.16748 9.74999 6.6009C9.74999 7.47622 9.40758 8.3157 8.7981 8.93464C8.18861 9.55359 7.36197 9.90131 6.50002 9.90131C5.63808 9.90131 4.81144 9.55359 4.20195 8.93464C3.59246 8.3157 3.25006 7.47622 3.25006 6.6009C3.25006 5.72558 3.59246 4.88611 4.20195 4.26717C4.81144 3.64822 5.63808 3.3005 6.50002 3.3005V3.3004Z"
                        fill="#007474"
                      />
                    </svg>
                  </span>
                  30 Place de la Nation, 75012 Paris, France
                </p>
                <p className="info-item">
                  <span className="info-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                    >
                      <path
                        d="M12.5569 10.906L12.1019 11.359C12.1019 11.359 11.0189 12.435 8.06386 9.49698C5.10886 6.55898 6.19186 5.48298 6.19186 5.48298L6.47786 5.19698C7.18486 4.49498 7.25186 3.36698 6.63486 2.54298L5.37486 0.859979C4.61086 -0.160021 3.13586 -0.29502 2.26086 0.57498L0.690856 2.13498C0.257856 2.56698 -0.032144 3.12498 0.002856 3.74498C0.092856 5.33198 0.810856 8.74498 4.81486 12.727C9.06186 16.949 13.0469 17.117 14.6759 16.965C15.1919 16.917 15.6399 16.655 16.0009 16.295L17.4209 14.883C18.3809 13.93 18.1109 12.295 16.8829 11.628L14.9729 10.589C14.1669 10.152 13.1869 10.28 12.5569 10.906Z"
                        fill="#007474"
                      />
                    </svg>
                  </span>
                  <b>01 43 43 41 38</b>
                </p>
                <p className="info-item">
                  <span className="info-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM10.45 9.5V4.75H8.55V11.4H14.25V9.5H10.45Z"
                        fill="#007474"
                      />
                    </svg>
                  </span>
                  Ouvert du lundi au dimanche 24/24h,&nbsp;
                  <span style={{ fontWeight: "bold", display: "contents" }}>
                    service continu
                  </span>
                </p>
              </div>
            </div>
            <div className="subsubsubsection">
              <div className="container-section2-content">
                <div className="section2-content column1">
                  <p className="description">
                    <span>Le dalou</span> vous propose une carte à l’image de la
                    cuisine française et qui change régulièrement, dans un décor
                    art déco typiquement parisien. Les spécialités plébiscitées
                    par nos clients ne varient guère mais on vient pour la
                    tradition – choucroute en tête – autant que pour les
                    plateaux de fruits de mer.
                  </p>
                  <div className="section2-buttons">
                    <a
                      href="https://wiicmenu-qrcode.com/app/offre.php?resto=678"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="button1">Nos cartes et formules</div>
                    </a>
                    <a
                      href="mailto:contact@brasserie-ledalou.fr"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="button1">Contactez-nous !</div>
                    </a>
                  </div>
                  <div className="info-section">
                    <div className="info-title">
                      Infos pratiques<span className="title-sideline"></span>
                    </div>
                    <div className="info-content">
                      <p className="info-item">
                        <span className="info-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="23"
                            viewBox="0 0 13 23"
                            fill="none"
                          >
                            <path
                              d="M6.50002 0C2.91018 0 0 2.95534 0 6.6009C0.00260718 7.72013 0.285462 8.82044 0.548929 9.30929L6.50002 23L12.1876 9.79819H12.1846C12.7188 8.81981 12.9994 7.71962 13 6.6009C13 2.95534 10.0899 0 6.50002 0ZM6.50002 3.3004C6.92682 3.3004 7.34944 3.38577 7.74375 3.55164C8.13806 3.71751 8.49634 3.96062 8.79813 4.2671C9.09992 4.57358 9.33931 4.93743 9.50263 5.33786C9.66595 5.7383 9.75 6.16748 9.74999 6.6009C9.74999 7.47622 9.40758 8.3157 8.7981 8.93464C8.18861 9.55359 7.36197 9.90131 6.50002 9.90131C5.63808 9.90131 4.81144 9.55359 4.20195 8.93464C3.59246 8.3157 3.25006 7.47622 3.25006 6.6009C3.25006 5.72558 3.59246 4.88611 4.20195 4.26717C4.81144 3.64822 5.63808 3.3005 6.50002 3.3005V3.3004Z"
                              fill="#007474"
                            />
                          </svg>
                        </span>
                        30 Place de la Nation, 75012 Paris, France
                      </p>
                      <p className="info-item">
                        <span className="info-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                          >
                            <path
                              d="M12.5569 10.906L12.1019 11.359C12.1019 11.359 11.0189 12.435 8.06386 9.49698C5.10886 6.55898 6.19186 5.48298 6.19186 5.48298L6.47786 5.19698C7.18486 4.49498 7.25186 3.36698 6.63486 2.54298L5.37486 0.859979C4.61086 -0.160021 3.13586 -0.29502 2.26086 0.57498L0.690856 2.13498C0.257856 2.56698 -0.032144 3.12498 0.002856 3.74498C0.092856 5.33198 0.810856 8.74498 4.81486 12.727C9.06186 16.949 13.0469 17.117 14.6759 16.965C15.1919 16.917 15.6399 16.655 16.0009 16.295L17.4209 14.883C18.3809 13.93 18.1109 12.295 16.8829 11.628L14.9729 10.589C14.1669 10.152 13.1869 10.28 12.5569 10.906Z"
                              fill="#007474"
                            />
                          </svg>
                        </span>
                        <b>01 43 43 41 38</b>
                      </p>
                      <p className="info-item">
                        <span className="info-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                          >
                            <path
                              d="M9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM10.45 9.5V4.75H8.55V11.4H14.25V9.5H10.45Z"
                              fill="#007474"
                            />
                          </svg>
                        </span>
                        Ouvert du lundi au dimanche 24/24h,&nbsp; <br />
                        <span
                          style={{ fontWeight: "bold", display: "contents" }}
                        >
                          service continu
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section2-content column2">
                  <div className="images">
                    <div className="left-image">
                      <img
                        src={Moules}
                        className="img-section2"
                        alt="Moules du Dalou"
                      />
                    </div>
                    <div className="right-image">
                      <img
                        src="https://brieffr.s3.eu-west-3.amazonaws.com/ledalou/dalou-2.jpeg"
                        className="img-section2"
                        alt="Choucroute du Dalou"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2-scroll desktop">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="61"
              viewBox="0 0 32 61"
              fill="none"
            >
              <path
                d="M3 16C3 12.5522 4.36964 9.24559 6.80761 6.80761C9.24559 4.36964 12.5522 3 16 3C19.4478 3 22.7544 4.36964 25.1924 6.80761C27.6304 9.24559 29 12.5522 29 16V29C29 32.4478 27.6304 35.7544 25.1924 38.1924C22.7544 40.6304 19.4478 42 16 42C12.5522 42 9.24559 40.6304 6.80761 38.1924C4.36964 35.7544 3 32.4478 3 29V16Z"
                stroke="#8B252A"
                stroke-width="4.33333"
                stroke-linejoin="round"
              />
              <path
                d="M16.0002 11.6667V20.3334"
                stroke="#8B252A"
                stroke-width="4.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 53.9167L15.4583 58.7917L21.9583 53.9167"
                stroke="#8B252A"
                stroke-width="3.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </section>

      <section id="section3">
        <h1 className="section3-title">
          <span className="title1">Notre instagram</span>
          <br />
          <span className="title2">à la Parisienne</span>
        </h1>
        <div className="img-carousel desktop">
          {/* <div className="arrow-left" onClick={() => changeSlide(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="17"
              viewBox="0 0 8 17"
              fill="none"
            >
              <path
                d="M6.5 1L1 8.5L6.5 15.5"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> */}
          <div
            className="slider"
            ref={thirdSliderRef}
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            <div className="slide">
              <img src={Insta1} alt="" />
              <img src={Insta2} alt="" />
              <img src={Insta3} alt="" />
              <img src={Insta4} alt="" />
              <img src={Insta5} alt="" />
            </div>
          </div>
          {/* <div className="arrow-right" onClick={() => changeSlide(1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="17"
              viewBox="0 0 8 17"
              fill="none"
            >
              <path
                d="M1 15.5L6.5 8L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> */}
        </div>
        <div
          className="slider-container thirdSlider mobile"
          ref={thirdSliderRef}
        >
          <div
            className="slider"
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            {thirdSlides.map((slide, index) => (
              <img key={index} src={slide} alt={`Slide ${index + 1}`} />
            ))}
          </div>
          {/* <button className="prev" onClick={prevSlide}>
            &lt;
          </button>
          <button className="next" onClick={nextSlide}>
            &gt;
          </button> */}
        </div>
        <a
          href="https://www.instagram.com/le.dalou/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="insta-btn">Suivez-nous sur @le.dalou</div>
        </a>
        <Footer />
      </section>
    </>
  );
};

export default Body;
